<template>
  <label-cmpt
    :showTitle="'关键字-个人阅读'"
    :showRefreshDataCmp="true"
    :taskType="'keywords'"
    :midType="'user'"
    :labelType="'keyword'"
  ></label-cmpt>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import labelCmpt from "./labelCmptNew.vue";

export default {
  name: "keywordUserStats",
  components: {
    labelCmpt,
  },

  data() {
    return {};
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
</style>
